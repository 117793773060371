import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaGithub } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer
      className="bg-light py-4 mt-5 pt-5"
      style={{
        background: "rgba(255, 255, 255, 0.66)", // Adds opacity
        backdropFilter: "blur(5px)", // Smoothens opacity effect
        position: "relative",
        zIndex: 10,
        borderTopColor:"lightgray",
        borderWidth:1,
        borderTopStyle:"solid"
      }}
    >
     
      <Container>
        <Row className="d-flex align-items-start">
          {/* Logo Section */}
          <Col xs={6} className="d-flex flex-column align-items-start">
            <h5 className="fw-bold mb-3 d-flex align-items-center">
              <img
                src="/media/U2.png"
                alt="Logo"
                width="50"
                height="40"
                style={{ borderRadius: "10px" }}
                className="me-2"
              />
              URLPDQ
            </h5>
            <p className="mb-1" style  = {{marginTop:-15,marginLeft:15}}>© 2025 urlpdq.com</p>
            {/* <p className="mb-1">All rights reserved</p>
            <a
              href="https://github.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-dark"
            >
              <FaGithub size={20} />
            </a> */}
          </Col>
          {/* Company Section */}
          <Col xs={6} className="text-end">
            <h6 className="fw-bold mb-3">Company</h6>
            <ul className="list-unstyled mb-0">
              <li>
                <Link to="/about" className="text-decoration-none text-dark">
                  About us
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-decoration-none text-dark">
                  Contact us
                </Link>
              </li>
              <li>
                <Link to="/terms" className="text-decoration-none text-dark">
                  Terms of use
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="text-decoration-none text-dark">
                  Privacy policy
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
