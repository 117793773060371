import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomNavbar from "./navbar";
import "./contactUs.css";
import Footer from "./footer";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy bg-white pt-5" >
      <CustomNavbar />
     <Container  fluid className="pt-4 ">
        <Row className="justify-content-center text-center mb-5 p-0" style={{ alignItems: "center" }}>
          <Col lg={8} style={{ alignSelf: "center" }}>
            <h1 className="text-center fw-bold">Privacy Policy</h1>
            <p className="text-center text-muted">Effective Date: December 6, 2024</p>

            <p className="text-center" style={{ fontSize: "20px", color: "black" }}>
              At URLPDQ, your privacy is our priority. This Privacy Policy outlines how we collect,
              use, and safeguard your personal information when you use our website and services.
              By accessing or using URLPDQ, you agree to the terms of this Privacy Policy.
            </p>

            {/* Sections */}
            <h4 className="fw-bold mt-4 terms-text1">Information We Collect</h4>
            <p className="terms-text-para1">
              <strong>A. Information You Provide</strong>
              <ul className="terms-text-para1">
                <li>
                  <strong>Account Information:</strong> When you sign up, we collect your name,
                  email address, and password.
                </li>
                <li>
                  <strong>Customization:</strong> If you create branded URLs, we may collect
                  additional information, such as your domain name.
                </li>
              </ul>
            </p>
            <p className="terms-text-para1">
              <strong>B. Automatically Collected Information</strong>
              <ul>
                <li>
                  <strong>Usage Data:</strong> IP addresses, browser type, operating system, and
                  device information.
                </li>
                <li>
                  <strong>Link Analytics:</strong> Data like click timestamps, location, device
                  type, and referring URL for created links.
                </li>
              </ul>
            </p>
            <p className="terms-text-para1">
              <strong>C. Cookies and Tracking Technologies</strong>
              <br />
              We use cookies, web beacons, and similar technologies to enhance your experience and
              analyze user interactions with our site and links.
            </p>
            <hr />

            <h4 className="fw-bold mt-4 terms-text1">How We Use Your Information</h4>
            <ul className="terms-text-para1">
              <li>Provide and improve our services.</li>
              <li>Analyze link performance and deliver analytics to users.</li>
              <li>Communicate with you regarding updates, promotions, or support.</li>
              <li>Protect against fraudulent activity and ensure site security.</li>
              <li>Comply with legal obligations.</li>
            </ul>
            <hr />

            <h4 className="fw-bold mt-4 terms-text1">Sharing Your Information</h4>
            <p className="terms-text-para1">
              We do not sell your personal information. However, we may share your data in the
              following circumstances:
            </p>
            <ul className="terms-text-para1">
              <li>
                <strong>Service Providers:</strong> Trusted third parties that assist in hosting,
                analytics, and customer support.
              </li>
              <li>
                <strong>Legal Compliance:</strong> To comply with laws, regulations, or court
                orders.
              </li>
              <li>
                <strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale
                of assets.
              </li>
            </ul>
            <hr />

            <h4 className="fw-bold mt-4 terms-text1">Data Retention</h4>
            <p className="terms-text-para1">
              We retain your data as long as necessary to provide our services and fulfill legal
              obligations. You may request the deletion of your data by contacting us at
              <strong> support@urlpdq.com</strong>.
            </p>
            <hr />

            <h4 className="fw-bold mt-4 terms-text1">Your Rights</h4>
            <ul className="terms-text-para1">
              <li>Access, update, or delete your personal information.</li>
              <li>Opt-out of marketing communications.</li>
              <li>Control cookies and tracking preferences through browser settings.</li>
              <li>
                Residents of certain jurisdictions may have additional rights under local laws, such
                as the CCPA or GDPR.
              </li>
            </ul>
            <hr />

            <h4 className="fw-bold mt-4 terms-text1">Security</h4>
            <p className="terms-text-para1">
              We implement industry-standard security measures to protect your information.
              However, no system is entirely secure, and we cannot guarantee absolute protection.
            </p>
            <hr />

            <h4 className="fw-bold mt-4 terms-text1">Third-Party Links</h4>
            <p className="terms-text-para1">
              Our site may contain links to external websites. We are not responsible for the
              privacy practices of these sites.
            </p>
            <hr />
            <h4 className="fw-bold mt-4 terms-text1">Children’s Privacy</h4>
            <p className="terms-text-para1">
                URLPDQ is not intended for children under 13. We do not knowingly collect personal information from children.
            </p>
            <hr />

            <h4 className="fw-bold mt-4 terms-text1">Modifications to Privacy Policy</h4>
            <p className="terms-text-para1">
              URLPDQ reserves the right to update this Privacy Policy at any time. Changes will be
              posted on this page with the revised effective date. Your continued use of the
              service constitutes acceptance of the updated Privacy Policy.
            </p>
            <hr />

            <h4 className="fw-bold mt-4 terms-text1">Contact Us</h4>
            <p className="terms-text-para1">
              If you have questions or concerns about this Privacy Policy, please contact us:
            </p>
            <ul style = {{textAlign:"left"}}>
              <li>Email: support@urlpdq.com</li>
              <li>
                Mailing Address:
                <br />
                URLPDQ
                <br />
                524 Greathouse Drive
                <br />
                Richmond, KY 40475 USA
              </li>
            </ul>

            <p className="terms-text-para1">
              By using URLPDQ, you acknowledge that you have read, understood, and agree to this
              Privacy Policy.
            </p>
          </Col>
        </Row>
        <Footer/>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
