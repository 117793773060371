import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomNavbar from "./navbar";
import "./contactUs.css";
import Footer from "./footer";

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions bg-white pt-5" >
    <CustomNavbar />
    <Container  fluid className="pt-4 ">
        <Row className="justify-content-center text-center p-0" style = {{alignItems:"center"}}>
          <Col lg={8} style = {{alignSelf:"center"}}>
            <h1 className="text-center fw-bold ">Terms of Use</h1>
            {/* <p className="text-center text-muted mb-5">
              Effective Date: December 6, 2024
            </p> */}

            <p className="text-center" style = {{fontSize:"20px",color:"black"}}>
              Welcome to URLPDQ! By accessing or using our website and services,
              you agree to comply with these Terms and Conditions (“Terms”).
              Please read them carefully. If you do not agree to these Terms,
              you must not use our services.
            </p>

            {/* Sections */}
            <h4 className="terms-text1 fw-bold mt-4" >Overview of Services</h4>
            <p className="terms-text-para1">
              URLPDQ provides tools to create short URLs and offers analytics
              for tracking link performance. Users may register for free or
              purchase premium packages for additional features.
            </p>
            <hr/>

            <h4 className="fw-bold mt-4 terms-text1">Eligibility</h4>
            <ul className="terms-text-para1">
              <li>Be at least 18 years old or have parental consent if under 18.</li>
              <li>Agree to these Terms and comply with all applicable laws.</li>
              <li>Register for an account to access certain features.</li>
            </ul>
            <hr/>

            <h4 className="fw-bold mt-4 terms-text1">Account Registration</h4>
            <ul className="terms-text-para1">
              <li>
                You are responsible for maintaining the confidentiality of your
                account credentials.
              </li>
              <li>
                You agree to provide accurate, current, and complete
                information during registration.
              </li>
              <li>
                Notify us immediately if you suspect unauthorized access to
                your account.
              </li>
            </ul>
            <hr/>

            <h4 className="fw-bold mt-4 terms-text1">Payment Terms</h4>
            <ul className="terms-text-para1">
              <li>
                Certain features or services require payment for premium
                packages.
              </li>
              <li>
                All prices are displayed in USD and may be subject to applicable
                taxes.
              </li>
              <li>
                Payments are processed securely through our third-party payment
                provider. We do not store your payment details.
              </li>
              <li>
                Subscriptions automatically renew unless canceled before the
                billing cycle ends.
              </li>
            </ul>
            <hr/>

            <h4 className="fw-bold mt-4 terms-text1">User Responsibilities</h4>
            <ul className="terms-text-para1">
              <li>Use the service for lawful purposes only.</li>
              <li>
                Not create or share links containing malicious content, harmful
                code, or inappropriate material (e.g., spam, illegal activities,
                adult content).
              </li>
              <li>Not attempt to hack, scrape, or reverse-engineer our platform.</li>
              <li>
                Accept full responsibility for the content of the links you
                create and share.
              </li>
            </ul>
            <hr/>

            <h4 className="fw-bold mt-4 terms-text1">Intellectual Property</h4>
            <p className="terms-text-para1">
              All content, software, and materials on URLPDQ, including but not
              limited to logos, designs, and trademarks, are owned by URLPDQ or
              its licensors. You may not copy, reproduce, or distribute any
              content without prior written permission.
            </p>

            <h4 className="fw-bold mt-4 terms-text1">Cancellation and Termination</h4>
            <ul className="terms-text-para1">
              <li>
                <strong>User-Initiated Cancellation:</strong> You may cancel
                your account or subscription at any time through your account
                settings.
              </li>
              <li>
                <strong>Termination by URLPDQ:</strong> We reserve the right to
                suspend or terminate accounts that violate these Terms without
                prior notice.
              </li>
              <li>
                No refunds are provided for unused portions of subscriptions
                unless required by law.
              </li>
            </ul>
            <hr/>

            <h4 className="fw-bold mt-4 terms-text1">Limitation of Liability</h4>
            <p className="terms-text-para1">
              To the fullest extent permitted by law, URLPDQ and its affiliates
              will not be liable for any indirect, incidental, or consequential
              damages resulting from:
            </p>
            <ul className="terms-text-para1">
              <li>Your use or inability to use our services.</li>
              <li>Unauthorized access to your account.</li>
              <li>
                Any content or links created, shared, or accessed through our
                platform.
              </li>
            </ul>
            <hr/>

            <h4 className="fw-bold mt-4 terms-text1">Privacy Policy</h4>
            <p className="terms-text-para1">
              Your use of URLPDQ is also governed by our Privacy Policy, which
              explains how we collect, use, and protect your data.
            </p>
            <hr/>

            <h4 className="fw-bold mt-4 terms-text1">Modifications to Terms</h4>
            <p className="terms-text-para1">
              URLPDQ reserves the right to update these Terms at any time.
              Changes will be posted on this page with the revised effective
              date. Your continued use of the service after changes are made
              constitutes your acceptance of the updated Terms.
            </p>
            <hr/>

            <h4 className="fw-bold mt-4 terms-text1">Governing Law</h4>
            <p className="terms-text-para1">
              These Terms are governed by the laws of the United States and the
              state of Kentucky. Any disputes shall be resolved in the courts of
              Kentucky.
            </p>

            <h4 className="fw-bold mt-4 terms-text1">Contact Us</h4>
            <p className="terms-text-para1" style = {{textAlign:"left"}}>
              For questions or concerns about these Terms, please contact us:
            </p>
            <ul className="text-muted" style = {{textAlign:"left"}}>
              <li>Email: support@urlpdq.com</li>
              <li>
                Mailing Address:
                <br />
                URLPDQ
                <br />
                524 Greathouse Drive
                <br />
                Richmond, KY 40475 USA
              </li>
            </ul>

            <p className="terms-text-para1">
              By using URLPDQ, you acknowledge that you have read, understood,
              and agree to these Terms and Conditions. Thank you for choosing
              our platform!
            </p>
          </Col>
        </Row>
        <Footer/>
      </Container>
    </div>
  );
};

export default TermsAndConditions;
