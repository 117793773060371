import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import CustomNavbar from "./navbar";
import { FaLaptop } from "react-icons/fa";
import { TbMailPin } from "react-icons/tb";
import { FcAlarmClock } from "react-icons/fc";
import { CiMail } from "react-icons/ci";
import "./contactUs.css";
import Footer from "./footer";

const ContactUs = () => {
  return (
    <Container fluid className="p-0" >
      {/* Navbar */}
      <CustomNavbar />

      {/* Contact Us Section */}
      <div className="contact-us-section bg-white">
        <Container>
          <Row className="mb-5">
            <Col>
              <h1 className="text-center  fw-bold mb-4">Contact Us</h1>
              <p className="text-center">
                We’re here to help! Whether you have a question, need assistance, or want to provide feedback, the team at <strong>URLPDQ</strong> is ready to assist you.
              </p>
            </Col>
          </Row>

          {/* Contact Methods */}
          <Row className="g-4">
            {/* Email Section */}
            <Col md={6}>
              <Card className="shadow-sm">
                <Card.Body>
                  <h5 className="fw-bold mb-3"><CiMail size={30} color="black" /> Email Us</h5>
                  <p>For general inquiries, support, or feedback, please email us at:</p>
                  <a href="mailto:support@urlpdq.com" className="text-primary fw-bold">
                    support@urlpdq.com
                  </a>
                </Card.Body>
              </Card>
            </Col>

            {/* Phone Section */}
            <Col md={6}>
              <Card className="shadow-sm">
                <Card.Body>
                  <h5 className="fw-bold mb-3">📞 Phone Support</h5>
                  <p>Prefer to talk to us? Call us at:</p>
                  <p className="text-primary fw-bold">1-800-URL-PDQ1 (1-980-272-0362)</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Business Hours and Address */}
          <Row className="g-4 mt-4">
            {/* Business Hours */}
            <Col md={6}>
              <Card className="shadow-sm">
                <Card.Body>
                  <h5 className="fw-bold mb-3"><FcAlarmClock size={30} /> Business Hours</h5>
                  <p>We’re available to respond to your inquiries during the following hours:</p>
                  <ul>
                    <li className="list2">Monday to Friday: 9:00 AM – 6:00 PM (EST)</li>
                    <li className="list2">Saturday & Sunday: Closed</li>
                  </ul>
                </Card.Body>
              </Card>
            </Col>

            {/* Mailing Address */}
            <Col md={6}>
              <Card className="shadow-sm">
                <Card.Body>
                  <h5 className="fw-bold mb-3"><TbMailPin  size={30}/> Mailing Address</h5>
                  <p>Need to reach us by mail? Send correspondence to:</p>
                  <p className="text-primary fw-bold">
                    URLPDQ <br />
                    524 Greathouse, Richmond, KY, 40475, USA
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Online Support */}
          <Row className="g-4 mt-4">
            <Col>
              <Card className="shadow-sm">
                <Card.Body>
                  <h5 className="fw-bold mb-3"><FaLaptop size={30} /> Online Support</h5>
                  <p>For immediate assistance, check out our:</p>
                  <ul>
                    <li className="list2">
                      <a href="#!" className="text-primary fw-bold">
                        Help Center
                      </a>: Find answers to common questions and how-to guides.
                    </li>
                    <li className="list2">
                      <a href="#!" className="text-primary fw-bold">
                        Live Chat
                      </a>: Available during business hours for quick support.
                    </li>
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Feedback */}
          <Row className="g-4 mt-4">
            <Col>
              <Card className="shadow-sm">
                <Card.Body>
                  <h5 className="fw-bold mb-3">💡 Feedback & Suggestions</h5>
                  <p>We’re always looking for ways to improve. Share your thoughts and suggestions with us at:</p>
                  <a href="mailto:feedback@urlpdq.com" className="text-primary fw-bold">
                    feedback@urlpdq.com
                  </a>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      
        <Footer/>
      </div>

    </Container>
  );
};

export default ContactUs;
