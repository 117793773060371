import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import CustomNavbar from "./navbar";
import { useNavigate } from "react-router-dom";
import Footer from "./footer";

const AboutUs = () => {
  const navigate = useNavigate();
  
  const handleNavigate = () => {
    navigate("/"); // Navigate to the home page
  };

  return (
    <Container  fluid className="p-0 m-0 " >
      {/* Navbar */}
      <CustomNavbar />

      {/* About Us Section */}
      <Container  fluid className="pt-4 ">
      <div className="about-us-section bg-white p-0">
        <Row className="justify-content-center text-center  p-0" style = {{alignItems:"center"}}>
            {/* Left Column: Text Content */}
            <Col lg={8} style = {{alignSelf:"center"}}>
            <h1 className="fw-bold mb-3">About us</h1>
 
            <p className="text-muted" style = {{textAlign:"left",fontSize:"18px",color:"black"}}>
                Welcome to <strong>URLPDQ</strong>, your trusted partner in creating smart, traceable, and efficient short URLs. At <strong>URLPDQ</strong>, we believe that every link has a story to tell, and we’re here to help you simplify and track that story with ease.
              </p>
              <p className="text-muted mb-3" style = {{textAlign:"left",fontSize:"18px",color:"black",}}>
                Our mission is to provide individuals and businesses with a powerful yet user-friendly platform to generate short, memorable links that pack a punch. Whether you're managing marketing campaigns, tracking social media engagement, or simply sharing links with friends, <strong>URLPDQ</strong> offers a seamless way to create and monitor your links in real-time.
              </p>
              <h5 className="mt-4 fw-bold">Why Choose URLPDQ?</h5>
              <ul className="list-unstyled">
                <li className="mb-1 text-muted" style = {{textAlign:"left",fontSize:"18px",color:"black"}}>✅ Quick and Easy Link Shortening: Generate sleek, shareable links in just seconds.</li>
                <li className="mb-1 text-muted" style = {{textAlign:"left",fontSize:"18px",color:"black"}}>✅ Advanced Analytics: Gain insights into your link performance, including click rates, geographical data, and device usage.</li>
                <li className="mb-1 text-muted" style = {{textAlign:"left",fontSize:"18px",color:"black"}}>✅ Reliable and Secure: Our platform is designed with industry-leading technology to ensure your links are safe and always accessible.</li>
                <li className="mb-1 text-muted" style = {{textAlign:"left",fontSize:"18px",color:"black"}}>✅ Customizable Options: Stand out with branded links and tailor them to suit your style or campaign needs.</li>
              </ul>
              <p className="mb-4 text-muted" style = {{textAlign:"left",fontSize:"18px",color:"black"}}>
                At <strong>URLPDQ</strong>, we’re more than just a URL shortener—we’re a tool for empowerment. Whether you're a social media influencer, a small business owner, or a data enthusiast, our platform is here to optimize your online presence and provide actionable insights into your audience's behavior.
              </p>
              <Button variant="primary" size="lg" onClick={handleNavigate}>Create Your First Link</Button>

            </Col>
            <Footer/>
         
          </Row>
      </div>
      </Container>
    </Container>
  );
};

export default AboutUs;
