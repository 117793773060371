import React, { useState, useEffect } from "react";
import {Container,Row, Col, Form, Button, InputGroup, Modal,} from "react-bootstrap";
import AsyncStorage from "@react-native-async-storage/async-storage";
import "./navbar.css";
import CustomNavbar from "./navbar";
import logo from '../../logo.svg';
import { FaEllipsisV } from "react-icons/fa"; 
import axios from 'axios';
import { v4 as uuidv4 } from "uuid";
import Footer from "./footer";

const Home = () => {
    const [urlInput, setUrlInput] = useState("");
    const [shortenedUrls, setShortenedUrls] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [generatedUrl, setGeneratedUrl] = useState("");
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipMessage, setTooltipMessage] = useState("");
    const [showOptions, setShowOptions] = useState(false); 
    const [editingIndex, setEditingIndex] = useState(null);
    const [selectedUrl, setSelectedUrl] = useState(null); 
  
 
    useEffect(() => {
      const fetchUrls = async () => {
        const storedUrls = await AsyncStorage.getItem("storedUrls");
        if (storedUrls) {
          setShortenedUrls(JSON.parse(storedUrls));
        }
      };
      fetchUrls();
    }, []);

    const handleShortenUrl = async () => {
      if (!urlInput) {
        setTooltipMessage("Please enter a URL.");
        setShowTooltip(true);
        return;
      }
    
      if (!urlInput.startsWith("https:")) {
        setTooltipMessage("Please enter a valid URL starting with https:");
        setShowTooltip(true);
        return;
      }

    
      try {
        setShowTooltip(false);
        setShowPopup(true);
    
        const response = await axios.put(
          `${process.env.REACT_APP_BASE_URL}/api/shorten/`, // Replace 'get_shortenurl' with the actual endpoint
          { url: urlInput } 
        );

        console.log("responseee",response)
    
        const newUrl = response.data.short_url; // Adjust the key based on API response structure
       

        const favicon = `https://www.google.com/s2/favicons?sz=64&domain_url=${new URL(response.data.original_url).hostname}`;


        const newEntry = {
          id: uuidv4(), // Generate a unique ID for each entry
          original: response.data.original_url,
          short: newUrl,
          favicon: favicon,
        };
    
        const updatedUrls = [...shortenedUrls, newEntry];
        await AsyncStorage.setItem("storedUrls", JSON.stringify(updatedUrls));
    
        setShortenedUrls(updatedUrls);
        setGeneratedUrl(newUrl);
      } catch (error) {
        console.error("Error generating shortened URL:", error);
        setTooltipMessage("Failed to shorten the URL. Please try again.");
        setShowTooltip(true);
      }
    };
    
      const handleClosePopup = () => setShowPopup(false);
  
   
      const handleDelete = async (id) => {
        const updatedUrls = shortenedUrls.filter((entry) => entry.id !== id);
        setShortenedUrls(updatedUrls);
        await AsyncStorage.setItem("storedUrls", JSON.stringify(updatedUrls));
      };
     
      const handleOptionsToggle = (index) => {
        if (editingIndex === index) {
          setShowOptions(!showOptions);  // Toggle if the same item is clicked again
        } else {
          setEditingIndex(index);
          setShowOptions(true); // Show options if a new item is clicked
        }
        setSelectedUrl(shortenedUrls[index]);
      };

  return (
    <Container fluid className="p-0">
     <CustomNavbar/>
        <div style = {{marginTop:"10PX"}}>
        <h1 className="mb-3" style = {{marginTop:"50px"}}>URL shortener</h1>
        <p className="mb-4">
          Short link generator to create short trackable links
        </p>
        </div>
        {/* <Button variant="dark" className="cta-button">
          Create free account →
        </Button> */}
        <Col xs={12} md={{ span: 6, offset: 3 }} className="shortened-url-section">
            <InputGroup className="mb-3">
            <Form.Control
            type="text"
            placeholder="Enter your URL here..."
            className="url-input"
            value={urlInput}
            isInvalid={showTooltip} // Highlight input field on error
            onChange={(e) => {
                setUrlInput(e.target.value);
                setShowTooltip(false); // Hide tooltip on input change
            }}
            />
                <Button variant="dark" className=" p-3 shorten-button" style = {{borderTopRightRadius:10,borderBottomRightRadius:10}} onClick={handleShortenUrl}>
                    Shorten URL
                </Button>
            <Form.Control.Feedback type="invalid" tooltip>
            {tooltipMessage}
            </Form.Control.Feedback>
            </InputGroup>
            <h3 className="mt-4">Stored URLs</h3>
            {shortenedUrls.length > 0 ? (
                <ul className="list-group">
                {shortenedUrls.slice(0).reverse().map((entry, index) => (
                    <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                    <div style={{display:"flex",alignItems:"center"}}>
                        <div>
                        {entry?.favicon ? 
                          <img
                            src={entry.favicon}
                            alt="Favicon"
                            width="30"
                            height="30"
                            className="me-3"
                            onError={(e) => {
                              e.target.src = logo; 
                            }}
                          /> :
                            <img 
                            src={logo}
                            alt="Logo"
                            width="50"
                            height="50"
                            className="d-inline-block align-top"
                            />
                          }
                        </div>
                        <div style={{alignContent:"flex-start",alignItems:"flex-start",display:"flex",flexDirection:"column",justifyContent:"flex-start"}}>
                        <a href={entry?.short} target="_blank" rel="noopener noreferrer" style={{ marginBottom: -25,color:"#0040ff",textDecoration:"underline",maxWidth: "250px", }}>{entry.short}</a><br />
                         {/* <a href={entry?.original} target="_blank" rel="noopener noreferrer" style = {{color:"gray",fontWeight:"normal"}}>{entry.original}</a> */}
                         <a
                    href={entry?.original}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "gray",
                      fontWeight: "normal",
                      whiteSpace: "nowrap", // Prevent wrapping
                      overflow: "hidden", // Hide overflowed text
                      textOverflow: "ellipsis", // Show ellipsis for truncated text
                      maxWidth: "250px", // Adjust the max width for truncation
                      display: "block",
                    }}
                    title={entry.original} // Tooltip to show full URL on hover
                  >
                    {entry.original}
                  </a>
                         </div>
                    </div>
                    <Button
                    variant="outline-white"
                    size="sm"
                    onClick={() => handleOptionsToggle(index)} 
                  >
                    <FaEllipsisV style={{ cursor: "pointer"}} />
                  </Button>
                  {showOptions && editingIndex === index && ( 
                    <div
                    className="options-popup"
                    style={{
                        position: "absolute",
                        top: "100%",
                        right: 0,
                        background: "white",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        zIndex: 10,
                        alignContent:"center",
                        alignItems:"center"
                    }}
                    >
                    <Button
                         variant="outline-danger"
                        onClick={() => {
                        handleDelete(entry?.id);
                        setShowOptions(false); // Close the menu after action
                        }}
                        style={{ display: "block", textAlign: "center", padding: "5px 10px" }}
                    >
                        Delete
                    </Button>
                    </div>
                    )}
        
                    </li>
                ))}
                </ul>
            ) : (
                <p className="text-muted">No URLs have been shortened yet.</p>
            )}
        </Col>
        <Footer/>

        {/* </Row> */}



            <Modal show={showPopup} onHide={handleClosePopup} centered>
        <Modal.Header closeButton>
          <Modal.Title>Short Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-success">✅ Link shortened successfully!</p>
          <InputGroup>
            <Form.Control
              type="text"
              readOnly
              value={generatedUrl}
              className="form-control"
            />
            <Button
              variant="outline-secondary"
              className="p-3"
              onClick={() => navigator.clipboard.writeText(generatedUrl)}
            >
              Copy
            </Button>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" className="p-2" style = {{width:100}} onClick={handleClosePopup}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Home;
