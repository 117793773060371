import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom"; // Correct imports
import Home from "./home";
import AboutUs from "./aboutus";
import ContactUs from "./contactus";
import TermsAndConditions from "./terms&conditions";
import PrivacyPolicy from "./privacypolicy";

const MainRoutes = () => {
  return (
    <BrowserRouter> {/* Correctly use BrowserRouter instead of Router */}
      <div style={{ paddingTop: "70px" }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />


        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default MainRoutes;
